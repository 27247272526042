<template>
  <div class="news" ref="news">
         <van-tabs v-model="active" @click="vanTab" sticky color="#F6D0AB" line-width="20px" line-height="3px"  title-active-color="#F6D0AB" title-inactive-color="#ffffff" border background="#39394D">
                 <!-- <van-tab name="a" title="热点新闻">
                    <newList></newList>
                 </van-tab> -->
                 <van-tab name="a"  title="市场快讯" >
                        <div v-if="shiChangLoad">
                              <center id="loadingbox">
                                <van-loading  size="70px" />
                              </center>
                        </div>
                        <div v-if="!shiChangLoad">
                               <iframe ref="iframe" class="iframe" src="https://www.jin10.com/" width="100%" scrolling="auto" frameborder="yes" ></iframe>
                               <!-- <footer class="foot">
                               <a  class="app" href="https://beijingzxj.com/download.html">
                                  下载APP
                               </a>
                               </footer> -->
                        </div>
                 </van-tab>   
                 <van-tab name="b" title="财经日历">
                        <div v-if="caiJingLoad">
                              <center id="loadingbox">
                                <van-loading  size="70px" />
                              </center>
                        </div>
                        <div v-if="!caiJingLoad">
                              <iframe ref="iframe" class="iframeOne" src="https://rili.jin10.com/" width="100%" scrolling="auto" frameborder="yes" ></iframe>
                              <!-- <footer class="foot">
                              <a  class="app" href="https://beijingzxj.com/download.html">
                                      下载APP
                              </a>
                              </footer> -->
                         </div>

                 </van-tab>       
         </van-tabs> 
  </div>
</template>

<script>
import newList from './components/newList.vue'

export default {
    components:{
        newList,
    },
    data() {
        return {
          // 点击tabs标签的标识符
          active: 'a',
          // 市场快讯加载状态
          shiChangLoad:true,
          // 财经日历加载状态
          caiJingLoad:true
        };
      },
      methods:{
        // 点击标签时触发	回调参数name：标识符
      vanTab(name){
         if (name=='a') {
            setTimeout(()=>{
               this.shiChangLoad = false
            },500)
         }else if(name=='b'){
            setTimeout(()=>{
               this.caiJingLoad = false
            },500)
         }
      }
      },
      mounted(){
            setTimeout(()=>{
               this.shiChangLoad = false
            },500)

      }
}
</script>

 <style lang="scss" scoped>
 .news{
   overflow-y: hidden;
   height:100%;
   position:relative;
 }

::v-deep{
   .van-tabs--line{
      height:105%;
      position:relative;
      
   .van-tabs__wrap{
        border-bottom: 1px solid #E9E9E9 !important;
        z-index: 3;
        height: 80px;
        .van-tab--active {
               font-size: 32px !important;
               font-weight: 600;
               font-family: PingFang SC-Medium, PingFang SC;
               height:100%;
               position:relative;
               .van-tab__text{
                color: #f6d0ab;
               }
            }
        .van-tab{
            font-size: 26px;
            font-family: PingFang SC-Medium, PingFang SC;
            line-height: 80px;
        }
    }
 }
 .van-tabs__nav.van-tabs__nav--line {
    z-index: 2;
}
.van-tabs__content{
  height: 100%;
  .van-tab__pane{
    height: 100%;
  }
}
}

  // .iframe{
  //   position: absolute;
  //   // top: -6rem;
  //   bottom:6rem;
  //   z-index: 1;
  //   height:100%;
  // }
  .foot{
    width: 100%;
    height: 108px;
    background-color: red;
    z-index: 2;
    position: fixed;
    bottom: 100px;
    left: 0;
    background: url("~@/assets/images/xiaApp1.png") no-repeat;
    background-size: 100% 100%;
    .app{
      display: block;
      width: 180px;
      height: 64px;
      background-color: #ffb606;
      position: absolute;
      color: #fff;
      top: 50%;
      margin-top: -36px;
      right: 40px;
      text-align: center;
      line-height: 64px;
      border-radius: 64px;
      font-size: 30px;
    }
  }
  .iframeOne{
    position: absolute;
    top: -20px;
    z-index: 1;
    height:90%;
  }
  
@media screen and (max-height:800px ) {
  .iframe{
    position: absolute;
    bottom:5.1rem;
    z-index: 1;
    height:102%;
  }
}

@media screen and (min-height:800px ) {
  .iframe{
    position: absolute;
    bottom:6rem;
    z-index: 1;
    height:100%;
  }
}
#loadingbox {
  margin-top: 150px;
}
</style>

